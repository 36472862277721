.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: -9px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 12px !important;
		padding: .7rem 0;
		color: $colorSecondary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: $gray1 !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px $gray4;
		border-radius: 2rem;
		padding:  0 1rem;
		text-align: left;
		transition: all .2s;
		&:hover{
			background-color: $gray5;
		}
	}

	& .component {
		border: solid 1px $gray5;
		z-index: 10;
		border-radius: .5rem;
		box-shadow: 0 3px 10px 0 rgba(black, .25);

		& .items{
			overflow: auto;
			& .group{
				color: $gray1 !important;
				font-size: .9rem;
			}
		}
	}

}
