.imoveis_semelhantes{
	display: block;
	width: 100%;
	background-color: white;
	border: solid 1px $gray5;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);
	padding: 1rem;
	border-radius: 1rem;

	& h2{
		font-size: 1.1rem;
		text-align: left;
		color: $gray1;
		font-weight: 500;
		margin: 0;
	}

	& .container_miniaturas_semelhantes{
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		& .miniatura_interna{
			background-color: white;
			width: 100%;
			display: block;
			padding: .7rem;
			border: solid 1px lighten($gray4, 10);
			position: relative;
			border-radius: 1rem;
	
			& a{
				display: block;
				width: 100%;
		
				& .bloco_img{
					width: 40%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
		
					& .miniatura_imovel_img{
						display: block;
						width: 100%;
						background-color: $gray5;
						position: relative;
						user-select: none;
						border-radius: .5rem;
						padding-top: 70%;
						overflow: hidden;					
	
						& img{
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							object-fit: cover;
						}
					}
				}
			   
				& .bloco_informacoes{
					width: 60%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
					padding-left: 1rem;
	
					& .miniatura_informacoes{
						display: block;
						width: 100%;
	
						& .miniatura_titulo{
							display: block;
							width: 100%;
					
							& p{
								font-size: .9rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;
							}
						}
							
						& .codigo_imovel{
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
	
							& .imo_cod_semelhante{
								width: fit-content;
								background-color: $gray5;
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
								padding: .2rem 1rem;
								border-radius: 2rem;
							}
																
							& .miniatura_acao{
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
								background-color: transparent;
							}
						}
		
						& .miniatura_endereco{
							display: block;
							width: 100%;
							& p{
								font-size: .8rem;
								font-weight: 400;
								color: $gray2;
								margin: 0;
	
								& i{
									font-size: .7rem;
									font-weight: 600;
									color: $colorSecondary;
								}
							}
						}
	
						& .miniatura_preco{
							display: block;
							width: 100%;
	
							& p{
								width: 100%;
								text-align: left;
								font-size: 1rem;
								font-weight: 500;
								color: $colorTerciary;
								line-height: none;
								margin: 0;
							}
	
							& .tt{
								font-size: .75rem;
								color: $gray2;
								font-weight: 400;
								margin: .5rem 0 0 0;
							}
						}
					}
				}
				
				& .miniatura-imovel-btns-iconsX{
					display: flex;
					justify-content: space-around;
					align-items: center;
					width: 100%;
					background-color: lighten($gray5, 1);
					padding: .75rem 0;
					border-radius: 1rem;
			
					& ul{
						& li{
							color: $gray1;
							font-size: .8rem;
							font-weight: 400;
			
							& i{
								font-weight: 400;
								font-size: .9rem;
								color: $gray1;
							}
						}
			
					}
				}
			}
		}
	}

	
	& .btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		
		& a{
			& p{
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				background-color: lighten($gray4, 13);
				border-radius: 2rem;
				padding: .5rem 1.5rem;
				text-align: center;
				transition: all .2s ease-in-out;
				margin: 0;
	
				&:hover{
					background-color: $colorPrimary;
					color: white;
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.imoveis_semelhantes{
		& .container_miniaturas_semelhantes{
			& .miniatura_interna{
				& a{
					& .bloco_img{
						& .miniatura_imovel_img{
							padding-top: 100%;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1023px){
	.imoveis_semelhantes{
		& .container_miniaturas_semelhantes{
			& .miniatura_interna{
				background-color: white;
				width: 100%;
				display: block;
				padding: .7rem;
				border: solid 1px lighten($gray4, 10);
				position: relative;
				border-radius: 1rem;
		
				& a{
					& .bloco_img{
						width: 100%;
						display: block;
						margin: 0;
					}
				   
					& .bloco_informacoes{
						width: 100%;
						display: block;
						margin: 1rem 0 0 0;
						padding-left: 0;
						
						& .miniatura_informacoes{
							& .miniatura_endereco{
								& p{
									font-size: .8rem;
								}
							}

							& .codigo_imovel{
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
		
								& .imo_cod_semelhante{
									width: fit-content;
									background-color: $gray5;
									font-size: .8rem;
									font-weight: 400;
									color: $gray1;
									padding: .2rem 1rem;
									border-radius: 2rem;
								}
																	
								& .miniatura_acao{
									font-size: .8rem;
									font-weight: 400;
									color: $gray1;
									background-color: transparent;
								}
							}
			
							& .miniatura-imovel-btns-icons{
								display: flex;
								align-items: center;
								justify-content: space-around;
								width: 100%;
					
								& ul{
									& li{
										color: $gray2;
										font-size: .8rem;
										font-weight: 500;
		
										& span{
											font-size: 1.1rem;
											color: $gray1;
										}
									}
		
								}
							}
						}
					}
				}
			}
		}
	}
}