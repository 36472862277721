.footer1{
	box-shadow: 0 0 15px 5px rgba($gray3, .15);
	width: 100%;
	display: block;

	& .sections_footer {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1.3fr;
		gap: 1.5rem;
		width: 100%;

		& div {
			margin: 0;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1.5rem;

			& .logo_footer{
				display: block;
				width: 100%;
		
				& a{
					display: block;
					width: fit-content;
					& .img{
						width: calc(300px / 1);
						height: calc(100px / 1);
						display: block;
						
						& img {
							display: block;
							user-select: none;
							width: calc(300px / 1);
							height: calc(100px / 1);
						}
					}
				}
			}
		
			& .creci_footer{
				display: block;
				width: fit-content;
				margin: 0;
				border: solid 1px lighten($gray4, 10);
				border-radius: 2rem;
				padding: .2rem 1.5rem;
				font-size: .9rem;
				font-weight: 600;
			}
		
			& .rs_footer{
				display: flex;
				flex-direction: row;
				width: fit-content;
				margin: 0;
				gap: 1rem;
				justify-content: space-between;
				align-items: center;
				
		
				& a{
					margin: 0;
					display: inline-block;
					vertical-align: middle;
					border-right: none;
					padding: 0;
					
					& p{
						border-radius: .1rem;
						margin: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						
						& i{
							color: $colorTerciary;
							font-size: 1.3rem;
							transition: all .2s ease-in-out;
							margin: 0;
						}
					}
		
					&:hover{
						& p{
							& i{
								color: $colorSecondary;
							}
						}
		
						border-right: none;
						padding-right: none;
					}
				}
			}

			& .menu_footer{
				display: flex;
				flex-direction: column;
				gap: 1rem;
				width: 100%;
		
				& a{
					display: block;
					width: fit-content;

					& p{
						font-size: .9rem;
						font-weight: 500;
						color: $gray1;
						margin: 0;
					}
				}
			}
		
			& .contatofooter{
				display: block;
				width: 100%;
				
				& .tel_email_footer{
					display: inline-block;
					width: 100%;
					vertical-align: top;
		
					& h3{
						font-size: .9rem;
						font-weight: 600;
						color: $gray1;
					}
					
					& a{
						display: block;
						width: fit-content;
						& p{
							font-size: .8rem;
							font-weight: 400;
							color: $gray2;
							transition: all .2s;
							&:hover{
								color: $colorPrimary;
							}
						}
					}
				}
			}
			
			& .endereco_footer{
				display: block;
				width: 80%;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: $gray1;
		
					& i{
						color: $colorSecondary;
					}
				}
		
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: $gray2;
				}
		
				& a{
					width: fit-content;
					display: block;
					margin: 0;
					padding: 0;
					& p{
						margin: 0;
						padding: 0;
						font-size: .8rem;
						font-weight: 400;
						color: $colorPrimary;
						transition: all .2s;
						border-left: solid 2px transparent;
						&:hover{
							border-left: solid 1px $colorPrimary;
							padding-left: .5rem;
						}
					}
				}
			}
		
			& .horarioatendimento_footer{
				display: block;
				width: 100%;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: $gray1;
					& i{
						color: $colorSecondary;
					}
				}
		
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: $gray2;
				}
			}
		}
	}

	& .ContainerInferiorFooter {
		width: 100%;
		display: block;

		& .ItemContainerInferior {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;

			& p {
				margin: 0;
				font-size: .85rem;
				font-weight: 400;
				color: $colorQuarterly;
				user-select: none;
				& a {
					& span {
						color: $gray2;
						font-weight: 600;
						font-size: .85rem;
						transition: color .2s ease-in-out;
					}
	
					&:hover {
						& span {
							color: $colorSecondary;
						}
					}
				}
			}
			
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	
}

@media (max-width: 1023px){
	.footer1{

		& .sections_footer {
			display: grid;
			grid-template-columns: 1fr;
			gap: 3rem;

			& div {
				& .creci_footer{
					margin: auto;
				}
			}
		}

		& .ContainerInferiorFooter {
			& .ItemContainerInferior {
				& p {
					line-height: 1.75rem;
					text-align: center;
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}