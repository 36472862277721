
/* UX GALERIA */
.ux-galeria{
	display: block;
	position: relative;
	width: 100%;
	padding: 0;
	outline: none;
	overflow: hidden;
	border-radius: 1rem;
}
.galeria-full-screen{
	border-radius: 0;
	position: fixed !important;
	z-index: 9998 !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
}
.ux-galeria-canvas{
	position: relative;
	display: block;
	background-color: #646464;
	text-align: center;
	padding: 0px;
	margin: 0px;
	width: inherit;
	height: inherit;
	min-height: 150px;
	max-width: inherit;
	max-height: inherit;
	overflow: hidden;
	perspective: 100vw;
}
.ux-galeria-canvas div{
	display: table-cell;
	vertical-align: middle;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
	padding: 0;
	margin: 0;
}
.ux-galeria-canvas-hidden div{
	display: none;
}
.ux-galeria-canvas img{
	max-width: inherit;
	max-height: inherit;
	padding: 0;
	margin: 0;
	box-shadow: 0 0 20px 4px rgba(0,0,0,0.1);
}
.ux-galeria-top{
	background-color: rgba(0,0,0,0.5);
	position: absolute;
	z-index: 4;
	display: block;
	bottom: -70px;
	right: 0;
	left: 0;
	height: 100px;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
}
.ux-galeria-top:hover{
	bottom: 0;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
}
.ux-galeria-top-active{
	bottom: 0;
}
.ux-galeria-button-thumbs{
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-button-thumbs::before{
	display: block;
	color: #FFF;
	content: "\f03e";
	font-family: 'Objetiva Software Light' !important;
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-button-thumbs-active::before{
	color: #869dff;
}
.ux-galeria-slide{
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-slide::before{
	display: block;
	color: #FFF;
	content: "\f04b";
	font-family: 'Objetiva Software Light' !important;
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-slide-pause{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-slide-pause::before{
	display: block;
	color: #FFF;
	content: "\f04c";
	font-family: 'Objetiva Software Light' !important;
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-fullscreen{
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-fullscreen::before{
	display: block;
	color: #FFF;
	content: "\f065";
	font-family: 'Objetiva Software Light' !important;
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-fullscreen-exit{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-fullscreen-exit::before{
	display: block;
	color: #FFF;
	content: "\f066";
	font-family: 'Objetiva Software Light' !important;
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-thumbs{
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 95px);
	height: 95px;
}
.ux-galeria-thumbs-scroll{
	text-align: center;
	display: block;
	height: 60px;
	overflow: auto;
}
.ux-galeria-thumbs-scroll::-webkit-scrollbar{width: 10px; height: 10px;}
.ux-galeria-thumbs-scroll::-webkit-scrollbar-track-piece{background-color: rgba(0,0,0,0.0);}
.ux-galeria-thumbs-scroll::-webkit-scrollbar-thumb:vertical{  height: 5px;  background-color: #2a2a2a;}
.ux-galeria-thumbs-scroll::-webkit-scrollbar-thumb:horizontal{  width: 5px;  background-color: #2a2a2a;}
.ux-galeria-thumbs-scroll::-ms-clear { width : 0; height: 0;}
.ux-galeria-thumbs-scroll::-ms-reveal { width : 0; height: 0;}

.ux-galeria-thumbs img{
	width: auto;
	height: 50px;
	margin: 0 0 0 10px !important;
	cursor: pointer;
	border-radius: 2px;
}
.ux-galeria-left{
	position: absolute;
	z-index: 4;
	display: block;
	top: 50%;
	margin-top: -30px;
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 40px;
	opacity: 0.8;
}
.ux-galeria-left::before{
	display: block;
	color: #FFF;
	content: "\f104";
	font-family: 'Objetiva Software Light' !important;
	font-size: 60px;
	text-align: center;
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
	transition: all 0.25s ease-in-out;
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
}
.ux-galeria-left:hover::before{
	transform: scale(1.3);
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
}

.ux-galeria-right{
	position: absolute;
	z-index: 4;
	display: block;
	top: 50%;
	right: 0;
	margin-top: -30px;
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 40px;
	opacity: 0.8;
}
.ux-galeria-right::before{
	display: block;
	color: #FFF;
	content: "\f105";
	font-family: 'Objetiva Software Light' !important;
	font-size: 60px;
	text-align: center;
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
	transform-origin: center center;
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
}
.ux-galeria-right:hover::before{
	transform-origin: center center;
	transform: scale(1.3);
	@include animation('all 0.25s alternate');
	@include transition('all 0.25s ease-in-out');
}

.ux-galeria-legenda{
	width: 100%;
	height: 30px;
    text-align: center;
	text-shadow: 0px 1px 2px rgba(0,0,0,0.5), 1px 0px 2px rgba(0,0,0,0.5);
	color: #FFF;
	display: inline-block;
	vertical-align: middle;
	line-height: 30px;
	font-size: 14px;
	text-overflow: ellipsis;
	word-wrap: break-word;
	max-height: 30px;
	height: 100vh;
	overflow: hidden;
}
.ux-galeria-left:hover,
.ux-galeria-right:hover,
.ux-galeria-fullscreen:hover,
.ux-galeria-fullscreen-exit:hover,
.ux-galeria-slide:hover,
.ux-galeria-slide-pause:hover{
	opacity: 1;
}

.galeria-full-screen .ux-galeria-canvas{
	background-color: #000 !important;
}