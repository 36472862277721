.nav {
	display: block;
	width: 20vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99991;
	padding: 1rem 1.5rem;
	box-shadow: -5px 0 10px -5px rgba(black, .2);
	text-align: right;

	& .btn_close_menu {
		background-color: transparent;
		color: $colorTerciary;
		border: none;
		font-size: 2rem;
		cursor: pointer;
		width: fit-content;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}
	
	& a{
		& .nav-menu {
			color: $colorQuarterly;
			font-size: 1rem;
			font-weight: 500;
			margin: 0;
			padding: .5rem 0;
			@include border-box;
			transition: all .25s ease-in-out;
			display: block;
			border-radius: 2rem;

			&:hover{
				background-color: lighten($gray5, 2);
				padding-left: 1.25rem;
			}
		}

	}
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.nav {
		width: 25vw;
	}
}

@media (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		background-color: rgba(white, .99);
		& .nav-menu {
			color: $gray1;
			font-size: 1.4rem;
			margin: 0;
			padding: .6rem 1rem;
			@include border-box;
			transition: all .25s;
		}
		& .nav-flow {
			min-height: 50vh;
			height: 110vh;
		}
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}