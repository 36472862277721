.imo_telefones_btn{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 30px !important;
	z-index: 9999;
       
    & #whatsapp_lista_telefones{
        padding: .5rem;
        position: fixed;
        bottom: 2rem;
        right: 7rem;

        & p{
            background-color: white;
            border: solid 1px lighten($gray4, 10);
            padding: .5rem 1.5rem;
            font-size: 1rem;
            font-weight: 500;
            color: $gray1;
            border-radius: 2rem;
            text-align: center;
            user-select: none;
            cursor: pointer;
            box-shadow: 2px 2px 5px 0 rgba(black, .1);

            &:hover{
                background-color: $gray5;
            }
        }
        
    }

    & #wpp_pulsante{
        width: 100%;
		display: block;
		background-color: #25d366;
		margin: auto;
        width: 4.5rem;
        height: 4.5rem;
		border-radius: 50%;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		/* -webkit-animation: pulse 1.5s infinite; */
        position: relative;
        cursor: pointer;

        & i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 2rem;
            font-weight: 500;
        }

        & .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 25px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}
    }
}

.pai_section_dialog{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 8rem !important;
	z-index: 10000;
    
    & .section_dialog{
        display: block;
        width: 100%;
        padding: 1.5rem;
        border-radius: 1rem;
        background-color: white;
        position: relative;
        border: solid 1px lighten($gray4, 10);
        box-shadow: 5px 5px 7px 0 rgba(black, .1);
        
        & .btn_fechar_form_zap{
            position: absolute;
            top: -1rem;
            right: -1rem;
            background-color: #25d366;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 3px white;
            transition: all .2s ease-in-out;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;

            & i{
                color: white;
                text-align: center;
                padding: 0;
                transition: all .2s ease-in-out;
            }

            &:hover{
                background-color: white;
                border: solid 3px #25d366;
                & i{
                    color: #25d366;
                }
            }
        }

        & .dialog_wpp{
            display: block;
            width: 100%;
            padding: 0;

            & h2{
                text-align: center;
                font-size: 1.2rem;
                font-weight: 500;
                color: $gray1;
                margin: 0 0 .5rem 0;
            }

            & .under_p{
                text-align: center;
                width: 4rem;
                height: 2px;
                margin: auto;
                background-color: #25d366;
            }

            & .inputs_wpp{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                gap: 2rem;
                width: 100%;

                & .inputs_dados{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1rem;
                    margin: 0;
                    padding: 0;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: .8rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & input{
                        border: solid 1px lighten($gray4, 10);
                        border-radius: 2rem;
                        width: 100%;
                        display: block;
                        padding: .7rem 1rem;
                    }
        
                }

                & .input_msg{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1rem;
                    margin: 0;
                    padding: 0;
                    & div {
                        width: 100%;
                        margin: 0;
                    }
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: .8rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & textarea{
                        border: solid 1px lighten($gray4, 10);
                        border-radius: 1rem;
                        width: 100%;
                        height: 120px;
                        display: block;
                        padding: .7rem 1rem;
                    }

                    & button{
                        width: 100%;
                        border: none;
                        border-radius: 2rem;
                        background-color: #25d366;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                        padding: .5rem 1rem;
                        cursor: pointer;
                        & i{
                            color: white;
                            font-size: 1.2rem;
                            font-weight: 400;
                            vertical-align: text-bottom;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px){
    .imo_telefones_btn{
        bottom: 2rem !important;
        right: 1rem !important;
        z-index: 9999;
           
        & #whatsapp_lista_telefones{
            right: 6rem;
        }
    }

    .pai_section_dialog{
        right: 2rem !important;
        
        & .section_dialog{
            width: 85vw;
    
            & .dialog_wpp{
                max-height: 70vh;
                overflow: auto;

                & .inputs_wpp{
                    width: 100%;
                    flex-direction: column;
                    gap: 1rem;

                    & .inputs_dados{
                        & div {
                            width: 100%;
                            margin: 0;
                        }
                    }

                    & .input_msg{
                       gap: 0;
                    }
                }
            }
        }
    }
}