.header1{
	background-color: transparent;
	width: 100%;
}

.header1_2{
	background-color: transparent;
	width: 100%;
	transition: all .15s ease-in-out;
	box-shadow: 0 0 10px 1px rgba(black, .1);
	border-bottom: solid 1px $gray5;
	display: block;
	position: relative;
	
	& p{
		color: #717580 !important;
	}

	& .menu {
		& button {
			color: $colorPrimary!important;
			border: solid 1px lighten($gray4, 10) !important;
			& i {
				color: $colorSecondary !important;
			}

			&:hover{
				background-color: $gray5 !important;
			}
		}
	}
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .15);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}

#sub_menu_vendas{
	display: none;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 0%;
	top: 2.4rem;
	background-color: white;
	box-shadow: 0px 0px 10px rgba(black, .1);
	border-radius: .5rem;
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		padding: .5rem 0;

		& p{
			font-size: .85rem !important;
			font-weight: 500;
			background-color: none;
			padding: 0.5rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s;
			color: $gray2;

			&:hover{
				background-color: lighten($gray5, 2);
				padding-left: 2rem;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	padding: 0 5rem;

	& .superior {
		display: flex;
		width: 100%;
		gap: 3rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 3;

		/* GRUPO HEADER */
		& .logo {
			width: fit-content;
			margin: 0;
			@include border-box;
			padding-top: .5rem;
			
			& .img{
				width: calc(300px / 1.2);
				height: calc(100px / 1.2);
				display: block;
				
				& img {
					display: block;
					user-select: none;
					width: calc(300px / 1.2);
					height: calc(100px / 1.2);
				}
			}
		}

		& .session_menu{
			width: 100%;
			display: flex;
			justify-content: flex-start;
			margin: 0;
			
			& ul {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0%;
				padding: 0%;
				list-style: none;
				gap: 3rem;

				& .itens_header2{
					display: block;
					padding: 0;

					& a{
						justify-content: space-between;
						color: white;
						font-weight: 500;
						font-size: .85rem;
						transition: all .2s;
					}

					& .itemsession{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
	
							& .itemsession_title{
								vertical-align: middle;
								display: inline-block;
								width: 100%;
								font-size: .85rem;
								color:  white;
								text-align: center;
								text-transform: initial;
								font-weight: 500;
								padding: 0;
								@include border-box;
								transition: all .15s ease-in-out;
								
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  white;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas{
								display: initial;
							}
						}
						
					}
				}

				& .busca_inteligente{
					display: block;
					width: 100%;
					border: solid 1px lighten($gray4, 10);
					border-radius: 2rem;
					position: relative;
				
					& input{
						width: 100%;
						display: block;
						background-color: transparent;
						padding: .5rem 2rem .5rem 1rem;
						border: none;
						color: $gray1;
						&::placeholder{
							color: $gray3 !important;
						}
					}
				
					& .btn_buscar{
						background-color: $colorSecondary;
						width: fit-content;
						border: none;
						text-align: center;
						user-select: none;
						cursor: pointer;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						right: 0.75%;
						transform: translate(-0.75%, -50%);
						padding: .8rem;
						transition: all .2s ease-in-out;

						& i{
							color: white;
							font-size: .8rem !important;
							font-weight: 400;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}

						&:hover{
							background-color: darken($colorSecondary, 5);
						}
					}
				}

				& #busca_inteligente{
					display: none;
					width: 300px;
				}
			}
		}

		& .sessions {
			display: flex;
			width: 100%;
			justify-content: flex-end;
			margin: 0;

			& ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 3rem;
				margin: 0%;
				padding: 0%;
				list-style: none;

				& .itens_header{
					padding: 0;
					justify-content: space-between;
					
					& a{
						& p{
							color: white;
							font-weight: 500;
							font-size: .85rem;
							margin: 0;
							padding: 0;
							transition: all .15s ease-in-out;
						}
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 20%;
			margin: 0;

			& button{
				background-color: transparent;
				border: solid 1px white;
				border-radius: 2rem;
				color: white;
				padding: .5rem 0;
				cursor: pointer;
				font-size: .9rem;
				font-weight: 600;
				transition: all .2s ease-in-out;
				width: 100%;

				& i {
					transition: all .2s ease-in-out !important;
					color: white;
				}

				&:hover{
					background-color: white;
					color: $colorPrimary;

					& i {
						color: $colorSecondary;
					}
				}
			}
		}
	}
}

.headerindex {
	height: 55vh;
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: calc(55vh + 7rem);
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: calc(55vh + 7rem);
			background: linear-gradient(to bottom, rgba(black, .4), rgba(black, .2), transparent);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;
			height: calc(55vh + 7rem);
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg_header2.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center right 25%;
		}
	}
}

.header_mob{
	background-color: white;
	width: 100%;
	display: block;
	padding: .25rem 1rem;

	& .header-mob{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& .log{
			width: calc(300px / 1.5);
			height: calc(100px / 1.5);
			display: block;
			margin: 0;
			
			& img {
				display: block;
				user-select: none;
				width: calc(300px / 1.5);
				height: calc(100px / 1.5);
			}
		}

		& button{
			color: $colorQuarterly;
			background-color: transparent;
			border: none;
			border-radius: 2rem;
			padding: 0 1rem;
			cursor: pointer;
			font-weight: 600;
			transition: all .2s ease-in-out;
			& i{
				font-size: 2rem;
			}
		}
	}
}

.btn_menu_header{
	background-color: transparent !important;
	color: $colorPrimary !important;
	border: solid 1px lighten($gray4, 10) !important;

	& i {
		color: $colorSecondary !important;
	}

	&:hover {
		border-color: $gray4 !important;
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1201px) and (max-width: 1366px){

	.headerindex {
		height: calc(100vh - 4.9rem);

		& .container-bg-headerpc{
			height: 100vh;
	
			& .overlay_bg_home{
				height: 100vh;
			}
	
			& .bg-headerpc{
				height: 100vh;
				background-position: center bottom 25%;
			}
		}
	}

	.headerpc {
		padding: 0 2rem;

		& .superior {
			gap: 2rem;

			& .logo {
				padding-top: 0;
				& .img{
					width: calc(300px / 1.3);
					height: calc(100px / 1.3);
					
					& img {
						width: calc(300px / 1.3);
						height: calc(100px / 1.3);
					}
				}
			}

			& .session_menu{
				& ul {
					gap: 1.5rem;
					& .busca_inteligente{
						& .btn_buscar{
							right: 1.5%;
							transform: translate(5%, -48%);
						}
					}

					& #busca_inteligente{
						width: 230px;
					}
				}
			}

			& .sessions {
				width: 80%;
				& ul {
					gap: 1.5rem;
				}
			}

			& .menu {
				width: 25%;
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1200px){
	
	.headerindex {
		height: calc(100vh - 5.2rem);

		& .container-bg-headerpc{
			height: 100vh;
	
			& .overlay_bg_home{
				height: 100vh;
			}
	
			& .bg-headerpc{
				height: 100vh;
				background-position: center bottom 25%;
			}
		}
	}

	.headerpc {
		padding: .3rem 1.5rem;

		& .superior {
			gap: 1.5rem;

			& .logo {
				padding-top: 0;
				& .img{
					width: calc(300px / 1.4);
					height: calc(100px / 1.4);
					
					& img {
						width: calc(300px / 1.4);
						height: calc(100px / 1.4);
					}
				}
			}

			& .session_menu{
				& ul {
					gap: 1.5rem;
					& .busca_inteligente{
						& .btn_buscar{
							right: 1.5%;
							transform: translate(5%, -48%);
						}
					}

					& #busca_inteligente{
						width: 215px;
					}
				}
			}

			& .sessions {
				width: 80%;
				& ul {
					gap: 1.5rem;
				}
			}

			& .menu {
				width: 22.5%;
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: calc(100vh - 4.9rem);

		& .container-bg-headerpc{
			height: 100%;
	
			& .overlay_bg_home{
				height: 100%;
			}
	
			& .bg-headerpc{
				height: 100%;
				background-position: center left 35%;
			}
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
