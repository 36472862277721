.busca {
	background-color: transparent;
	display: block;
	width: 70%;
	text-align: center;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	z-index: 2;

	& .slogan{
		display: block;
		width: 100%;
		margin: 0;

		& h1{
			font-size: 1.8rem;
			font-weight: 600;
			color: white;
			text-shadow: 0 0 5px $gray1;
			text-align: center;
			line-height: 2.5rem;
		}
	
		& h2{
			font-size: 1rem;
			font-weight: 400;
			color: white;
			text-shadow: 1px 1px 3px $gray1;
			text-align: center;
		}
	}

	& .busca-filtro{
		width: 100%;
		display: block;
		background-color: white;
		padding: 1rem;
		border-radius: 3rem;
		box-shadow: 0 0 10px 1px rgba(black, .1);

		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: block;
			width: 100%;

			& .filtros_busca_home{
				width: 20%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}

			& .filtros_busca_home2{
				width: 25%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}

			& .filtros_busca_home_input{
				width: 17.5%;
				display: inline-block;
				padding: 0;
			}

			& .btn-home{
				padding: .6rem 0;
				border-radius: 2rem;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				background-color: $colorSecondary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border: none;
				cursor: pointer;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorSecondary, 5);
				}
				
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .45rem 0;
	border: none;
	border-radius: 2rem;
	margin-top: .15rem;
	background-color: $colorSecondary;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: $colorTerciary;
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .45rem 0;
	border: solid 1px $gray4;
	border-radius: 2rem;
	margin-top: .15rem;
	background-color: white;
	color: $gray1;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: $gray5;
	}
}

.btns_busca_interna_mobile{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: .5rem;
}

@media (min-width: 1024px) and (max-width: 1366px){

	.busca {
		display: block;
		width: 80%;
		top: 80%;
		left: 50%;
		transform: translate(-50%, -80%);

		& .slogan{
			& h1{
				font-size: 1.6rem;
				line-height: 2.25rem;
			}
		
			& h2{
				font-size: 1rem;
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;

			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
	
				& .filtros_busca_home{
					width: 20%;
					display: inline-block;
					padding: 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
	
				& .filtros_busca_home2{
					width: 25%;
					display: inline-block;
					padding: 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home_input{
					width: 17.5%;
					display: inline-block;
					padding: 0;
				}
			}
		}
	}

	.botao_buscar_int{
		padding: .6rem 0;
	}
	
	.botao_limpar_int{
		padding: .6rem 0;
	}
}

@media (max-width: 1023px){

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca {
		background-color: transparent;
		display: block;
		width: 90%;
		text-align: center;
		border: none;
		box-shadow: none;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10000;

		& .slogan{
			width: 100%;
			
			& h1{
				font-size: 1.5rem;
				text-align: center;
			}
		
			& h2{
				font-size: 1rem;
				text-align: center;
				line-height: 1.5rem;
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			padding: 1rem;
			border-radius: 1rem;

			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;

				& .filtros_busca_home{
					width: 100%;
					display: inline-block;
					padding: 0 0 1.5rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}

				& .filtros_busca_home2{
					width: 100%;
					display: inline-block;
					padding: 0 0 1.5rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}

				& .filtros_busca_home_input{
					width: 50%;
					display: inline-block;
					padding: 0;
				}

				& .btn-home{
					padding: .75rem 0;
				}

				& .item_buscaMenu{
					display: block;
					width: 100%;
				
					& .buscaMenu{
						display: block;
						width: 100%;
						& .elementos_buscaMenu_class{
							transition: all .2s ease-in-out;
							padding-left: 1.5rem;

							& p{
								text-align: left;
								font-size: .9rem;
								color: white;
								font-weight: 500;
							}
						}
					}
				
					& .title_is{
						& p{
							text-align: left;
							font-size: 1.1rem;
							color: white;
							font-weight: 500;
							padding: 0.1rem 0;
							border-bottom: solid 1px black;

							& i{
								display: inline-block;
								vertical-align: middle;
								font-size: .7rem;
								color: white;
								font-weight: 300;
							}
						}
					}
				}
			}
		}
	}
}

