.section_imobiliaria{
    display: block;
    width: 100%;

    & h1{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & h2{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & p{
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.75rem;
        color: black;
    }

    & ol {
        & li {
            line-height: 1.75rem;
            font-size: .9rem;
            color: black;
        }
    }

    & .section_equipe{
        display: block;
        width: 100%;
    
        & .miniatura_equipe{
            display: block;
            width: 100%;
            padding: .8rem;
            border: solid 1px $gray4;
            
            & .img{
                background-color: $colorPrimary;
                display: block;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
                & img{
                    width: 80%;
                    border-radius: 50%;
                    border: solid 5px $gray1;
                    box-shadow: 0 0 10px 1px rgba(black, .5);
                }
            }
    
            & .detalhes{
                display: block;
                width: 100%;
    
                & .nome{
                    font-size: 1rem;
                    font-weight: 600;
                    color: $colorTerciary;
                    text-transform: uppercase;
                }
        
                & p{
                    font-size: .8rem;
                    font-weight: 400;
                    color: $gray2;  
                }

                & a{
                    width: fit-content;
                    display: block;
                    
                    & p{
                        transition: all .2s;
                    }

                    &:hover{
                        & p{
                            color: $colorPrimary;
                        }
                    }
                }
            }
        }
    }
}

.nadaEncontradoImobiliaria {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& p {
		font-size: 1rem;
		font-weight: 400;
		color: $gray1;
		text-align: center;
		max-width: 70vw;
		line-height: 2rem;

		& span {
			font-weight: 500;
			font-style: italic;
		}
	}

	& a {
		width: fit-content;
		display: block;

		& p {
			background-color: $colorSecondary;
			color: white;
			padding: .2rem 1.5rem;
			border-radius: 2rem;
			transition: background-color .2s ease-in-out;
			font-weight: 500;

			&:hover {
				background-color: $colorTerciary;
			}
		}
	}
}

@media (max-width: 1023px) {
	.nadaEncontradoImobiliaria {
		& p {
			max-width: 100%;
			line-height: 2rem;
			font-size: 1.1rem;
		}
	}
}